<template>
  <nav class="block-navigation-steps">
    <!-- Desktop -->
    <ul
      class="block-navigation-steps__list is-desktop"
    >
      <li
        v-for="link, index in links"
        :key="link.id"
        class="block-navigation-steps__list-item"
      >
        <UtilRouteLink
          class="block-navigation-steps__list-item-link"
          :link="link.link"
        >
          {{ `${index + 1}.` }}&nbsp; {{ link.label }}
          <div class="pipe">
            |
          </div>
        </UtilRouteLink>
      </li>
    </ul>

    <!-- Mobile -->
    <div class="block-navigation-steps__list">
      <div class="block-navigation-steps__before">
        <slot name="before" />
      </div>

      <div class="block-navigatgion-steps__list-inner">
        <AtomIndicatorSteps
          :steps="links.length"
          :current-step="activeLinkIndex + 1"
        />
        <div
          v-if="activeLink"
          class="block-navigation-steps__list-item-link"
        >
          {{ activeLink.label }}
        </div>
      </div>

      <div class="block-navigation-steps__after">
        <slot name="after" />
      </div>
    </div>
  </nav>
</template>

<script setup>
const props = defineProps({
    textColor: {
        type: String,
        default: 'var(--c-black)',
    },

    links: {
        type: Array,
        required: true,
    },
});

const {
    textColor,
} = toRefs(props);

const route = useRoute();
const activeLink = computed(() => props.links.find((link) => route.path.includes(link.link)) || { label: 'Benefits' });
const activeLinkIndex = computed(() => {
    const index = props.links.findIndex(
        (link) => route.path.includes(link.link),

    );

    return index === -1 ? 0 : index;
});
</script>

<style lang="scss" scoped>
.block-navigation-steps {
    color: v-bind(textColor);
    font-family: var(--f-family--secondary-bold);

    @include tablet {
        font-family: var(--f-family--teritary);
    }
}

.block-navigation-steps__list {
    @include fluid-simple('margin-bottom', 20px, 83px);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 5px;
    list-style: none;

    &.is-desktop {
        display: none;
        padding-top: 26px;
        padding-bottom: 20px;

        @include tablet {
            display: flex;
        }
    }

    &:not(.is-desktop) {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        @include tablet {
            display: none;
        }
    }

    @include tablet {
        flex-direction: row;
    }
}

.block-navigation-steps__list-item-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;

    .pipe {
        color: var(--c-black);
    }
}

.block-navigation-steps__list-item {
    font-size: 14px;

    .router-link-active {
        color: var(--c-primary);
        font-weight: var(--f-weight--700);

        .pipe {
            font-weight: var(--f-weight--400);
        }
    }

    &:last-child {
        .pipe {
            display: none;
        }
    }
}

.block-navigatgion-steps__list-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    gap: 6px;
    text-align: center;
}

.block-navigation-steps__list-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.block-navigation-steps__before,
.block-navigation-steps__after {
    display: flex;
    width: calc(100% / 4);
}

.block-navigation-steps__after {
    justify-content: flex-end;
}
</style>
