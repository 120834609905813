<template>
  <div
    class="layout-default"
    :class="{ 'has-main-content': hasMainContent }"
  >
    <BlockLayoutHeader />

    <div class="layout-default__inner">
      <div class="layout-default__navigation">
        <UtilRouteLink
          v-if="backLink?.cached_url && stepNumber === 0"
          :link="backLink.cached_url"
          class="layout-default__navigation-back-button"
        >
          <AtomButton
            icon="arrow-left"
            button-size="small"
            icon-size="18px"
            icon-position="left"
            :text="getStoryblokTranslation('general', 'back')"
          />
        </UtilRouteLink>
        <BlockNavigationSteps
          :links="mappedHeaderLinks"
          :class="{ 'is-hidden': stepNumber === 0 }"
        >
          <template #before>
            <UtilRouteLink v-if="backLink?.cached_url" :link="backLink.cached_url">
              <AtomButton
                icon="arrow-left"
                button-size="small"
                icon-size="18px"
              />
            </UtilRouteLink>
          </template>

          <template #after>
            <div :style="'min-height: 60px;'">
              <ClientOnly>
                <AtomIndicatorCircleprogress
                  v-if="isArrayNotEmpty(mappedHeaderLinks)"
                  :current-step="stepNumber"
                  :is-hidden="!getIsHidden"
                  width="60px"
                  :image="recruiter?.image"
                  height="60px"
                  :steps="mappedHeaderLinks.length"
                />
              </ClientOnly>
            </div>
          </template>
        </BlockNavigationSteps>
      </div>

      <div class="layout-default__content-wrapper">
        <div class="layout-default__content">
          <div
            :class="{
              'has-main-content': hasMainContent,
              'is-hidden': stepNumber === 0,
            }"
            class="layout-default__column is-intro"
          >
            <UtilRendererStoryblok
              v-if="isArrayNotEmpty(topContent)"
              :data="topContent"
              tag="div"
              :disable-grid="true"
            />

            <div class="layout-default__progress-wrapper">
              <ClientOnly>
                <AtomIndicatorCircleprogress
                  v-if="isArrayNotEmpty(mappedHeaderLinks)"
                  :show-progress="(stepNumber !== 1 && stepNumber !== mappedHeaderLinks.length)"
                  :current-step="stepNumber"
                  :is-hidden="getIsHidden"
                  :width="indicatorSize"
                  :height="indicatorSize"
                  :image="recruiter?.image"
                  :steps="mappedHeaderLinks.length"
                  class="layout-default__progress"
                />
              </ClientOnly>
            </div>

            <UtilRendererStoryblok
              v-if="isArrayNotEmpty(introContent)"
              :data="introContent"
              tag="div"
              :disable-grid="true"
            />
          </div>

          <div
            :class="{
              'is-hidden': !hasMainContent,
              'has-main-content': hasMainContent,
            }"
            class="layout-default__column"
          >
            <div class="layout-default__slot">
              <slot />
            </div>
            <div id="slider-buttons-teleport-target" />
          </div>
        </div>
      </div>

      <div
        class="layout-default__quick-navigation"
        :class="linkVisibility"
      >
        <BlockNavigationButton :buttons="nextLinks" />
      </div>
    </div>

    <BlockLayoutFooter
      v-if="isArrayNotEmpty(mappedFooterLinks)"
      :links="mappedFooterLinks"
      :text="getStoryblokTranslation('general', 'coop_copyright')"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    topContent: {
        type: Array,
        default: () => [],
    },

    introContent: {
        type: Array,
        default: () => [],
    },

    stepNumber: {
        type: Number,
        default: null,
    },

    nextButtons: {
        type: Array,
        default: () => [],
    },

    backLink: {
        type: Object,
        default: () => {},
    },

    nextLinks: {
        type: Array,
        default: () => [],
    },

    hasMainContent: {
        type: Boolean,
        default: false,
    },

    linkVisibility: {
        type: String,
        default: '',
    },

    forceIndicatorOnMobile: {
        type: Boolean,
        default: false,
    },
});

const { forceIndicatorOnMobile } = toRefs(props);
const { locale } = useI18n();
const recruiter = inject('recruiter', {});

const headerLinks = computed(() => getStoryblokSetting('navigation', 'header_navigation'));
const mappedHeaderLinks = computed(
    () => (isArrayNotEmpty(headerLinks.value) ? headerLinks.value.map((link) => ({
        link: useMapStoryblokLink(link.link, locale.value),
        _uid: link._uid,
        label: link.label,
    })) : []),
);

const footerLinks = computed(() => getStoryblokSetting('navigation', 'footer_navigation'));
const mappedFooterLinks = computed(
    () => (isArrayNotEmpty(footerLinks.value) ? footerLinks.value.map((link) => ({
        link: useMapStoryblokLink(link.link, locale.value),
        _uid: link._uid,
        label: link.label,
    })) : []),
);

/*
  Indicator
*/
const getIsHidden = computed(() => {
    if (isLowerThanTablet.value) {
        return !(forceIndicatorOnMobile.value === true);
    }

    return false;
}, { immediate: true });

/*
  Indicator Size
*/
const indicatorSize = computed(() => {
    if (forceIndicatorOnMobile.value && isLowerThanTablet.value) {
        return '150px';
    }

    return '230px';
});
</script>
<style lang="scss" scoped>
.layout-default {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.layout-default__inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: var(--page-padding);
    margin-left: var(--page-padding);
}

.layout-default__navigation {
    .block-navigation-steps {
        &.is-hidden {
            display: none;
        }
    }
}

.layout-default__navigation-back-button {
    display: flex;
    height: 100%;
    padding-top: 26px;
    padding-bottom: 20px;
}

.layout-default__content-wrapper {
    display: flex;
    height: 100%;
    flex: 1;
}

.layout-default__content {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    overflow-y: auto;
    row-gap: 40px;

    @include tablet {
        @include grid-layout(1, 12, 12);

        flex-direction: row;
    }

}

.layout-default__column {
    &.is-intro {
        @include fluid-simple('padding-bottom', 0px, 78px);

        display: flex;
        flex-direction: column;
        align-items: center;
        grid-column-end: span 1;
        grid-column-start: 1;
        row-gap: 25px;

        &.has-main-content  {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        &:not(.has-main-content)  {
            display: flex;
            max-width: 780px;
            flex-direction: column;
            align-items: center;
            align-self: center;
            justify-content: center;
            padding-bottom: 0;
            column-gap: 35px;
            grid-column-end: span 12;
            grid-column-start: 1;
            justify-self: center;

            @include tablet {
                flex-direction: row;
            }
        }

        @include tablet {
            grid-column-end: span 6;
            grid-column-start: 4;

            &.has-main-content {
                @include fluid('padding-right', 0px, 0px, 165px);
                align-items: flex-start;
                justify-content: center;
                grid-column-end: span 4;
                grid-column-start: 1;
                --flex-basis: calc(100% / 12 * 4);
            }
        }

        .main-content {
            @include fluid-simple('gap', 20px, 30px);

            display: flex;
            width: 100%;
            flex-direction: column;
        }

        &.is-hidden {
            display: none;
        }
    }

    &:not(.is-intro) {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        grid-column-end: span 1;

        &.is-hidden {
            display: none;
        }

        @include tablet {
            grid-column-end: span 8;
        }
    }
}

.layout-default__slot {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.layout-default__quick-navigation {
    @include fluid-simple('margin-top', 40px, 50px);
    margin-bottom: 55px;

    &.only-mobile {
        display: block;

        @include tablet {
            display: none;
        }
    }

    &.only-desktop {
        display: none;

        @include tablet {
            display: block;
        }
    }

    &.both {
        display: block;
    }
}

#slider-buttons-teleport-target {
    justify-self: flex-end;
}
</style>
