<template>
  <div
    class="atom-indicator-circleprogress"
    :class="{
      'is-hidden': isHidden,
    }"
  >
    <ClientOnly>
      <svg
        v-if="showProgress"
        class="atom-indicator-circleprogress__progress-circle"
        viewBox="0 0 100 100"
      >
        <circle class="background" cx="50" cy="50" r="47.5" />
        <circle class="progress" cx="50" cy="50" r="47.5" :style="circleStyle" />
      </svg>
    </ClientOnly>

    <AtomImage
      class="atom-indicator-circleprogress__image"
      :class="{ 'progress-hidden': !showProgress }"
      :image="image"
      provider="strapi"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Object,
        required: true,
        validator: (value) => typeof value === 'object',
    },

    width: {
        type: String,
        default: '100%',
    },

    height: {
        type: String,
        default: '100%',
    },

    steps: {
        type: Number,
        default: 5,
    },

    currentStep: {
        type: Number,
        default: 1,
    },

    progressColor: {
        type: String,
        default: 'var(--c-secondary)',
    },

    showProgress: {
        type: Boolean,
        default: true,
    },

    isHidden: {
        type: Boolean,
        default: false,
    },
});

const {
    width,
    height,
    steps,
    currentStep,
    progressColor,
} = toRefs(props);

const progress = computed(() => (currentStep.value / steps.value) * 100);

const circleStyle = computed(() => {
    const radius = 47.5;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress.value / 100) * circumference;

    return {
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: offset,
    };
});
</script>

<style lang="scss" scoped>
.atom-indicator-circleprogress {
    position: relative;
    display: flex;
    width: v-bind(width);
    height: v-bind(height);
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.is-hidden {
        display: none;
    }

    .background {
        fill: none;
        stroke: var(--c-grey);
        stroke-width: 5px;
    }

    .progress {
        fill: none;
        stroke: v-bind(progressColor);
        stroke-linecap: round;
        stroke-width: 5px;
        transform: rotate(270deg);
        transform-origin: center;
        transition: stroke-dashoffset 0.4s;
    }
}

.atom-indicator-circleprogress__progress-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.atom-indicator-circleprogress__image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &.progress-hidden {
        margin: 0;
    }

    ::v-deep(.atom-image__background) {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
